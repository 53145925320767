.intro {
    display: flex;
    height: 100vh;
    text-align: center;

}

.i-left{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.i-right {
    flex: 1;
    position: relative;
   
}

.i-left-wrapper {
    padding: 50px;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.i-intro{
    font-size: 30px;
    font-weight: 300;
}

.i-name{
    font-size: 40px;
    font-family: Lucida Console, Helvetica, sans-serif;

}
.i-title {
    height: 50px;
    overflow: hidden;
}

.i-title-wrapper{
    height: 100%;
    padding-left: 20px;
    animation: move 10s ease-in-out infinite alternate-reverse; 
}

@keyframes move {
    25%{
        transform: translateY(-50px);
    }
    50%{
        transform: translateY(-100px);
    }
    75%{
        transform: translateY(-150px);
    }
    100%{
        transform: translateY(-200px);
    }
}

.i-item-title {
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #59b595;
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.i-bg {
    clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
    background-color: #59b595;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;

}

.i-img {
    flex: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left:0;
    right: 100%;
}

@media screen and (max-width: 480px) {
    .intro {
        flex-direction: column;
    }
    .i-left-wrapper{
        padding: 10px;
        align-items: center;
        text-align: center;
        justify-content: center;
        height: 100%;
    }
    .i-desc {
       display: none; 
    }
}

